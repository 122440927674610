<template>
  <div id="expectedPrice">
    <van-nav-bar title="报价" left-arrow :border="false" @click-left="goLastPage" right-text="注意事项" @click-right="dialogNote">
    </van-nav-bar>
    <div class="brand">
      <img src="../../../../../assets/images/logo.png" alt="" />
    </div>
    <div class="registeredWrap">
      <div class="public">
        <svg t="1602144828769" class="icon iconMoney" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3323" width="32" height="32">
          <path d="M829.834 587.506c24.882 0 47.189 15.443 47.189 37.754 0 18.876-22.308 36.038-47.189 36.038H566.42V877.52c0 18.876-18.02 41.184-47.189 41.184-26.599 0-48.909-22.308-48.909-41.184V661.298H218.063c-24.882 0-45.476-17.163-45.476-37.754s20.592-36.038 45.476-36.038H471.18V475.104H218.063c-24.882 0-45.476-14.586-45.476-37.754 0-18.876 20.592-35.178 45.476-35.178h217.938L260.108 166.216c-8.579-18.876-2.572-47.189 18.876-57.486 23.169-11.155 47.189-0.857 59.203 14.586l182.759 246.253 186.192-228.234c12.014-17.163 37.754-39.47 68.643-21.451 32.605 19.733 12.014 58.347 12.014 58.347L605.036 401.319h214.506c24.882 0 47.189 15.443 47.189 36.896s-22.308 36.038-47.189 36.038H566.425v112.402l263.414 0.857z" p-id="3324" fill="#000"></path>
        </svg>
        <input type="number" v-model="submitPrice" />
        <span class="unit">元</span>
        <p style="margin-top: 3rem; font-size: 1.4rem">
          报价成功后需扣除 1.00元 意外保险费用
        </p>
      </div>
    </div>
    <van-field v-model="message" rows="2" autosize label="留言" type="textarea" maxlength="50" placeholder="请输入留言" show-word-limit />

    <div class="protocol">
      <van-checkbox v-model="checked" checked-color="#ff816c">
        阅读并同意 《师傅意外保协议》《亿师傅订单服务协议》
      </van-checkbox>
    </div>
    <div class="submitPrice">
      <p class="submitPriceNum">
        <span v-if="submitPrice">总价:</span>
        <span style="color: red">
          {{ submitPrice }}
        </span>
        <span v-if="submitPrice">元</span>
      </p>
      <button class="submit" @click="confirmPrice">确认提交</button>
    </div>
    <van-popup v-model="show" closeable position="bottom" :style="{ height: '45%' }">
      <div class="popupSure">
        <!-- <p>报价金额: {{ submitPrice }} 元</p>
        <p>服务费</p> -->
        <van-cell-group>
          <van-cell title="报价金额" :value="submitPrice + ' 元'" title-style="title-style" />
          <div style="margin: 2rem 0">
            <p>一旦提交则不可修改</p>
            <p>虚假报价则依照规则进行处罚</p>
          </div>
          <!-- <van-cell title="服务费" :value="5 + ' 元'" label="" /> -->
          <van-button type="primary" :block="isBlock" style="margin: 3rem 0; font-size: 1.6rem" color="linear-gradient(to right, #ff6034, #ee0a24)" @click="confirmSubmit">确认报价</van-button>
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import TopBar from "@/components/home/TopBar.vue";
import { Dialog } from "vant";
import store from "@/store/index.js";
export default {
  data() {
    return {
      submitPrice: undefined,
      checked: true,
      show: false,
      isBlock: true,
      message: "",
      pid: 0,
    };
  },
  components: {
    TopBar,
  },
  methods: {
    dialogNote() {
      Dialog.alert({
        title: "报价注意事项",
        message: `<p>1.师傅务必根据实际情况合理给出报价。</p>\n<p>2.报价之后将不能修改报价金额,若因虚假报价产生不良后果,平台将会依照<span style="color: red">《违规处罚规则》</span>处罚师傅。</p>\n<p>3.师傅报价后将默认当前<span style="color: red">《服务承诺》</span>,并在网站端显示,如产生纠纷将作为依据。</p>\n<p>4.所有订单师傅在报价/抢单成功后默认购买意外保，意外保将在师傅上门签到成功后生效,意外保费用将在订单交易成功后自动扣除。请 您务必了解<span style="color: red">《师傅意外保险协议》</span></p>`,
        messageAlign: "left",
        confirmButtonText: "我知道了",
      }).then(() => {
        // on close
      });
    },
    toLastIndex() {
      this.$router.go(-1);
    },
    confirmPrice() {
      if (this.checked && this.submitPrice) {
        this.show = true;
      } else {
        Toast("请先同意协议且输入报价");
      }
    },
    confirmSubmit() {
      this.$http
        .post(`/api/v2/worker/order/${this.$route.params.orderId}/quote`, {
          message: this.message,
          price: this.submitPrice,
        })
        .then((res) => {
          this.$toast(res.data.message)
          this.goSubPage("quoted_detail", this.$route.params.orderId);
        })
        .catch((err) => {
          Toast(err.error);
        });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less">
#expectedPrice {
  .brand {
    width: 100%;
    padding: 6rem 0;
    background: #ff816c url("../../../../../assets/1.png") no-repeat bottom /
      cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 40%;
    }
    p {
      font-size: 1.4rem;
      color: #fff;
      padding: 0.5rem;
    }
  }
  .registeredWrap {
    box-sizing: border-box;
    width: 90%;
    // padding: 20rem 0;
    padding: 4rem 0;
    border-radius: 10px;
    height: 20rem;
    background: #fff;
    position: absolute;
    left: calc(50% - 45%);
    top: 19rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .public {
      width: 88%;
      height: 4.6rem;
      padding: 1rem;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        border: none;
        border-bottom: 1px solid #ff8a76;
        text-indent: 6rem;
        font-size: 3.2rem;
        font-weight: bold;
      }
      .iconMoney {
        position: absolute;
        left: 2rem;
        bottom: 2rem;
      }
      .unit {
        position: absolute;
        right: 2rem;
        bottom: 2rem;
        font-size: 1.4rem;
      }
    }
  }
  .van-field {
    width: 90%;
    margin: 19rem auto;
  }
  .protocol {
    bottom: 80px;
    box-sizing: border-box;
    padding: 0 2rem 80px 2rem;
    font-size: 1.4rem;
  }
  .submitPrice {
    width: 100%;
    height: 60px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .submit {
      width: 30%;
      height: 35px;
      border: none;
      background: linear-gradient(to right, #ff6034, #ee0a24);
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
      border-radius: 60rem;
      text-align: center;
      line-height: 35px;
      margin-right: 2rem;
    }
    .submitPriceNum {
      font-size: 1.8rem;
      margin: 0 2rem;
      white-space: nowrap;
    }
  }
  .popupSure {
    width: 100%;
    font-size: 1.8rem;
    padding: 8rem 3rem 0 3rem;
    box-sizing: border-box;
    height: 15rem;
    p {
      text-align: center;
      font-size: 1.4rem;
      color: red;
    }
    .van-cell {
      font-size: 1.8rem;
      font-weight: bold;
    }
    .van-cell__value {
      color: #f42629;
    }
  }
}
</style>